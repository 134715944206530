import { MenuOutlined } from "@ant-design/icons"
import CustomProTable from "@components/CustomProTable"
import { TableEmptyState } from "@components/TableEmptyState"
import { useStores } from "@hooks/use-stores"
import tableColumns from "@modules/Banners/components/ListEssayBanners/tableColumns"
import styles from "@modules/Banners/style.module.less"
import { Button, Col, Modal } from "antd"
import arrayMove from "array-move"
import cx from "classnames"
import dayjs from "dayjs"
import { navigate } from "gatsby"
import React, { useCallback, useState } from "react"
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc"

const ListEssayBanner = () => {
  const { banners } = useStores()
  const { getAllEssayBannersAdmin, deleteEssayBanner, reorderEssayBanners } =
    banners

  const [essayBanners, setEssayBanners] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const getEssayBanners = useCallback(async (params) => {
    if (params) {
      if (params.releaseDate) {
        params.releaseDate = dayjs(params.releaseDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )
      }
    }

    const { data } = await getAllEssayBannersAdmin(params)

    setEssayBanners(data)

    return data
  })

  const handleEditBanner = (data) => {
    const path = `/admin/banners/editar/${data.id.toString()}`
    navigate(path, {
      state: { dataBanner: data, id: data.id, edit: true },
    })
  }

  const handleDeleteBanner = async ({ id }) => {
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esse item?",
      onOk: async () => {
        await deleteEssayBanner([id])
        await getEssayBanners()
      },
      okType: "danger",
      okText: "Sim",
      cancelText: "Não",
    })
  }

  const handleDeleteMultipleBanners = async () => {
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esses itens?",
      onOk: async () => {
        await deleteEssayBanner(selectedRowKeys)
        await getEssayBanners()

        setSelectedRowKeys([])
      },
      okType: "danger",
      okText: "Sim",
      cancelText: "Não",
    })
  }

  const DragHandle = sortableHandle(() => (
    <MenuOutlined className="pointer comet" />
  ))

  const SortableItem = sortableElement((props) => <tr {...props} />)
  const SortableContainer = sortableContainer((props) => <tbody {...props} />)

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newArray = arrayMove(
        [].concat(essayBanners),
        oldIndex,
        newIndex
      ).filter((el) => !!el)

      setEssayBanners(newArray)

      await reorderEssayBanners(newArray.map((item) => item.id))
    }
  }

  const DraggableContainer = (props) => {
    if (essayBanners.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={7}>
              <TableEmptyState />
            </td>
          </tr>
        </tbody>
      )
    }

    return (
      <SortableContainer
        useDragHandle
        helperClass={cx("dragging-row", styles.rowDragging)}
        onSortEnd={onSortEnd}
        {...props}
      />
    )
  }

  const DraggableBodyRow = ({ ...restProps }) => {
    const key = restProps["data-row-key"]

    if (!key) {
      return null
    }
    const index = essayBanners.findIndex((x) => x.id === key)

    return <SortableItem index={index} {...restProps} />
  }

  return (
    <div className="bannerEssayContext">
      <Col span={24} className="pt24">
        <div className="bannerEssayTable">
          <CustomProTable
            headerTitle="Listagem de banners"
            columns={tableColumns({
              handleEditBanner,
              handleDeleteBanner,
              DragHandle,
            })}
            dataSource={essayBanners}
            request={getEssayBanners}
            rowKey="id"
            rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }}
            pagination={false}
            scroll={{ x: true }}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            toolBarRender={() => [
              <Button
                key="button"
                type="primary"
                className="button-essay"
                onClick={() => navigate("/admin/banners/cadastrar")}
              >
                Cadastrar banner
              </Button>,
            ]}
            tableExtraRender={() => [
              selectedRowKeys && selectedRowKeys.length > 1 && (
                <div
                  className={cx(
                    "flex justify-between pa16 bg-french-rose",
                    styles.essayDelete
                  )}
                >
                  <div>
                    <span className="f14 bold lh-copy white">
                      {selectedRowKeys.length > 1
                        ? `${selectedRowKeys.length} `
                        : ""}
                    </span>
                    <span className="f14 bold lh-copy white">
                      {selectedRowKeys.length > 1 ? "Itens selecionados" : ""}
                    </span>
                  </div>
                  <label
                    className="f14 bold lh-copy white bb pointer"
                    onClick={() => handleDeleteMultipleBanners()}
                  >
                    EXCLUIR
                  </label>
                </div>
              ),
            ]}
          />
        </div>
      </Col>
    </div>
  )
}

export default ListEssayBanner
