import { Icon } from "@components/Icons/index"
import { CategoryByNumber } from "@modules/Essay/store"
import { Input } from "antd"
import dayjs from "dayjs"
import React from "react"
import InputMask from "react-input-mask"

const tableColumns = ({ handleEditBanner, handleDeleteBanner, DragHandle }) => [
  {
    title: "Ordernar",
    dataIndex: "order",
    className: "drag-visible",
    render: () => <DragHandle />,
    hideInSearch: true,
  },
  {
    title: "Ordem",
    dataIndex: "order",
    className: "drag-visible",
    key: "index",
    render: (text, record, index) => (
      <label
        className="pointer normal"
        onClick={() => handleEditBanner(record)}
      >
        {index + 1}
      </label>
    ),
    hideInSearch: true,
  },
  {
    title: "Modelo",
    dataIndex: "modelName",
    key: "modelName",
    render: (text, record) => (
      <label
        className="pointer normal french-rose"
        onClick={() => handleEditBanner(record)}
      >
        {text}
      </label>
    ),
  },
  {
    title: "Lançamento",
    dataIndex: "releaseDate",
    key: "releaseDate",
    render: (date, record) => (
      <label
        className="pointer normal"
        onClick={() => handleEditBanner(record)}
      >
        {dayjs(date).isValid() ? dayjs(date).format("DD/MM/YYYY") : "-"}
      </label>
    ),
    renderFormItem: () => (
      <InputMask
        mask={"99/99/9999"}
        maskChar={null}
        placeholder="Informe o filtro"
      >
        {(inputProps) => <Input {...inputProps} />}
      </InputMask>
    ),
  },
  {
    title: "Categoria",
    dataIndex: "category",
    key: "category",
    render: (value, record) => (
      <label
        className="pointer normal"
        onClick={() => handleEditBanner(record)}
      >
        {CategoryByNumber[value] || "-"}
      </label>
    ),
    hideInSearch: true,
  },
  {
    title: "Ação",
    dataIndex: "action",
    render: (text, record) => (
      <Icon
        className="pointer normal"
        name="IcDelete"
        onClick={() => handleDeleteBanner(record)}
      />
    ),
    hideInSearch: true,
  },
]

export default tableColumns
