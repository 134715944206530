import { LayoutAdmin } from "@components/LayoutAdmin"
import ListEssayBanners from "@modules/Banners/components/ListEssayBanners"
import { observer } from "mobx-react"
import React from "react"

const AdminEssayBanners = () => {
  return (
    <LayoutAdmin title="Banners" subTitle="Visualize e gerencie os banners.">
      <ListEssayBanners />
    </LayoutAdmin>
  )
}

export default observer(AdminEssayBanners)
